const IndexAdminUserRegister = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/user-register/index.vue"
  );

const ListRegisterUser = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/user-register/list.vue"
  );

export default [
  {
    path: "user-register",
    name: "IndexAdminUserRegister",
    component: IndexAdminUserRegister,
    meta: { requiresAuth: true, title: "User Register", key: "User Register" },
    children: [
      {
        path: "",
        name: "ListRegisterUser",
        component: ListRegisterUser,
        meta: { title: "User Register", key: "User Register" },
      },
    ],
  },
];
