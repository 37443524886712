const IndexHome = () =>
  /* webpackChunkName: "view-[request]" */ import("@/views/index.vue");

const ContentHome = () =>
  /* webpackChunkName: "view-[request]" */ import("@/views/Pages/HomeView.vue");

const TentangKami = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Pages/TentangKamiView.vue"
  );

const Registrasi = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Pages/registrasi.vue"
  );

const Agenda = () =>
  /* webpackChunkName: "view-[request]" */ import("@/views/Pages/agenda.vue");

const FAQ = () =>
  /* webpackChunkName: "view-[request]" */ import("@/views/Pages/faq.vue");

const Galeri = () =>
  /* webpackChunkName: "view-[request]" */ import("@/views/Pages/galeri.vue");

const Location = () =>
  /* webpackChunkName: "view-[request]" */ import("@/views/Pages/location.vue");

export default [
  {
    path: "",
    name: "home",
    component: IndexHome,
    meta: { title: "Beranda" },
    children: [
      {
        path: "/",
        name: "registrasi",
        component: Registrasi,
        meta: { title: "Registrasi" },
      },
      // {
      //   path: "/",
      //   name: "registrasi",
      //   component: ContentHome,
      //   meta:{title:"Beranda"}
      // },
      // {
      //   path: "/tentang-kami",
      //   name: "tentang-kami",
      //   component: TentangKami,
      //   meta:{title:"Tentang Kami"}
      // },
      // {
      //   path: "/registrasi",
      //   name: "registrasi",
      //   component: Registrasi,
      //   meta:{title:"Registrasi"}
      // },
      // {
      //   path: "/agenda",
      //   name: "agenda",
      //   component: Agenda,
      //   meta:{title:"Agenda"}
      // },
      //  {
      //   path: "/galeri",
      //   name: "galeri",
      //   component: Galeri,
      //   meta:{title:"Galeri"}
      // },
      // {
      //   path: "/faq",
      //   name: "faq",
      //   component: FAQ,
      //   meta:{title:"FAQ"}
      // },
      //   {
      //   path: "/lokasi",
      //   name: "lokasi",
      //   component: Location,
      //   meta:{title:"Lokasi"}
      // },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      /* webpackChunkName: "view-[request]" */ import("@/views/LoginView.vue"),
  },
];
