const AdminWelcome = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/welcome.vue"
  );

export default [
  {
    path: "/",
    name: "AdminWelcome",
    component: AdminWelcome,
    meta: { requiresAuth: true, title: "Dashboard", key: "dashboard" },
  },
];
