const IndexAdminUserReport = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/report/index.vue"
  );

const ListReportUser = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/report/list.vue"
  );

export default [
  {
    path: "report",
    name: "IndexAdminUserReport",
    component: IndexAdminUserReport,
    meta: { requiresAuth: true, title: "User Report", key: "User Report" },
    children: [
      {
        path: "",
        name: "ListReportUser",
        component: ListReportUser,
        meta: { title: "User Report", key: "User Report" },
      },
    ],
  },
];
