

import $axios from "@/utils/axios.js";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  GetAllAgendaPage(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/all-agenda")
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  getAgendaByClick(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/agenda/"+params.tanggal)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  GetAllAgenda(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/agenda")
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  Settings(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/setting", {
          params: {
            ...params
          }
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  GetPageAboutUs(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/about")
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  GetGalleryById(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/data-gallery/"+params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  GetMenuTabGalleries(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/menutabgalleries")
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  getPageFaq(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/faq")
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  
};

export default {
  namespace: true,
  state,
  getters,
  mutations,
  actions,
};
