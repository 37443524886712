const IndexAdmin = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Administrator/index.vue"
  );

import Administrator from "./administrator/index";

export default [
  {
    path: "/administrator",
    name: "homeadmin",
    component: IndexAdmin,
    meta: { requiresAuth: true },
    children: Administrator,
  },
];
