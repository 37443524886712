import Vue from "vue";
import Vuex from "vuex";
import Auth from "./auth.js"
import Administrator from "./administrator.js"
import Page from "./page.js"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false,
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    Auth,
    Administrator,
    Page
  },
});
