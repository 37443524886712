import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";
import Home from "./home";
import Administrator from "./administrator";

Vue.use(VueRouter);

const routes = [].concat(Home).concat(Administrator);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let auth = store.getters.isAuth;

  if (to.matched.some((record) => record.meta.requiresAuth) && auth) {
    if (to.name == "homeadmin") {
      next({
        path: "/administrator",
        replace: true,
      });
    } else {
      next();
    }
  } else {
    if (to.matched.some((record) => record.meta.requiresAuth) && !auth) {
      next({
        path: "/login",
        replace: true,
      });
    } else {
      next();
      //console.log('donah')
    }
  }
});

export default router;
