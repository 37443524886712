import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Urls from "@/utils/url.js";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import VueGtag from "vue-gtag";


Vue.config.productionTip = true;
Vue.use(Antd);
Vue.mixin(Urls);
Vue.use(CKEditor);

Vue.use(VueGtag, {
  config: { id: "G-4RQT0MK1SH" }
});


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
