import $axios from "@/utils/axios.js";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  Dashboard(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/administrator/dashboard")
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  AgendaInsert(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .post("/administrator/agenda/create", {
          ...params,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  GetListAgenda(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/administrator/agenda", {
          params: {
            ...params,
          },
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  GetListDetailAgenda(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/administrator/agenda/list-detail/" + params.id, {
          params: {
            ...params,
          },
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  ResendEmail(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .post("/administrator/resend-email", {
          ...params,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  OpenCloseRegistration(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .post("/administrator/open-close-registration", {
          ...params,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  GetAboutUs(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/administrator/about")
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  GetDataFaq(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/administrator/edit-faq/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  GalleryInsert(context, payload) {
    this.state.isLoading = true;
    const formData = new FormData();
    formData.append("photo", payload.File == undefined ? "" : payload.File);
    formData.append("filename", payload.filename);
    formData.append("description", payload.description);
    formData.append("categories", payload.categories);
    formData.append("sub_categories", payload.sub_categories);

    // console.log(payload.File);

    return new Promise((resolve, reject) => {
      $axios
        .post("administrator/create-galleries", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          const message = response.data.message;
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  InsertOrUpdateAboutUs(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .post("/administrator/about", {
          ...params,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  InsertFaq(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .post("/administrator/faq-create", {
          ...params,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  AgendaDetailEdit(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .put("/administrator/agenda/detail-edit/" + params.id, {
          ...params,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  AgendaDetailInsert(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .post("/administrator/agenda/detail-insert", {
          ...params,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  DeleteDetailAgenda(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .delete("/administrator/agenda/delete-detail/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  AgendaEdit(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .put("/administrator/agenda/set-edit-agenda/" + params.id, {
          ...params,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  getEditDetailAgenda(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/administrator/agenda/edit-agenda/detail/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  GetDataAgendaParents(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/administrator/agenda/edit-agenda/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  UpdateFaq(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .put("/administrator/edit-faq/" + params.id, {
          ...params,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  DeleteAgenda(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .delete("/administrator/agenda/delete/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  DeleteGalleries(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .delete("/administrator/delete-galleries/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  DeleteFaq(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .delete("/administrator/delete-faq/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  DeleteUser(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .delete("/administrator/delete-user/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  GetListGalleries(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/administrator/list-galleries", {
          params: {
            ...params,
          },
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  GetAllCategoriesGallery(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/administrator/get-categories")
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  GetListRegisterUser(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/administrator/list-user-register", {
          params: {
            ...params,
          },
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  GetListFaq(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/administrator/list-faq", {
          params: {
            ...params,
          },
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  ExportRegisterUser(context, values) {
    let postConfig = {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
      },
    };
    this.state.isLoading = true;
    //console.log (values)
    return new Promise((resolve, reject) => {
      $axios
        .post(
          "/administrator/export-register-user",
          {
            ...values,
          },
          postConfig
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "Data-User-" + new Date().getTime() + ".xls"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);

          //const message = response.data.message;
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  ExportFaq(context, values) {
    let postConfig = {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
      },
    };
    this.state.isLoading = true;
    //console.log (values)
    return new Promise((resolve, reject) => {
      $axios
        .post(
          "/administrator/export-faq",
          {
            ...values,
          },
          postConfig
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "Data-FAQ-" + new Date().getTime() + ".xls"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);

          //const message = response.data.message;
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
};

export default {
  namespace: true,
  state,
  getters,
  mutations,
  actions,
};
