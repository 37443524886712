export default {
  methods: {
    GetUrl() {
      //const URL = "http://localhost/summitbe/";
      const URL = "https://hiera-smm.com/be/";

      return URL;
    },
  },
};
